<template>
  <div>
    <div class="css-b0unca">
      <div class="w-full">
        <div class="flex w-full items-start justify-around py-[20px]">
          <div class="css-1wxaqej">
            <a class="css-mrhmgf" tabindex="0" href="https://www.saepio.co.uk"
              ><svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ArrowLeftRoundedIcon"
              >
                <path
                  d="M12.29 8.71 9.7 11.3c-.39.39-.39 1.02 0 1.41l2.59 2.59c.63.63 1.71.18 1.71-.71V9.41c0-.89-1.08-1.33-1.71-.7z"
                ></path></svg
              ><span class="text-sm">Back to Website</span></a
            >
          </div>
          <div class="flex justify-center">
            <img
              src="./assets/SAEPIO-Hub.71fc081e.png"
              alt="SAEPIO Logo"
              class="w-[50px] sm:w-[70px] md:w-[120px]"
            />
          </div>
          <div class="css-1wxaqej">
            <a
              class="MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-11y2tw2 text-sm"
              tabindex="0"
              type="button"
              href="https://saepio.arcocyber.com"
            >
              Customer Login
            </a>
          </div>
        </div>
        <div>
          <div>
            <div>
              <div class="w-full flex justify-center flex-wrap items-center">
                <div
                  class="lg:w-1/4 m-12 md:w-full flex flex-wrap flex-col md:min-w-[610px]"
                >
                  <div>
                    <h3 class="MuiTypography-h3 css-apz5wr">
                      "The first security report our board actually loved"
                    </h3>
                  </div>
                  <div class="pt-4">
                    <h6 class="hero-text">
                      Welcome to Saepio Hub. Our secure portal where we are able
                      to interact with customers and fast-track the Security
                      Improvement Journey that Saepio helps navigate. Currently
                      we can perform our Cyber Resilience Assessments, and our
                      CIS Control Infrastructure Reviews through the Hub, but
                      further services will be enabled in the future.
                    </h6>
                  </div>
                  <div class="pt-4">
                    <h6 class="hero-text">
                      Whether you're dealing with Saepio vCISO, Services, or
                      Solutions teams, Saepio Hub is at the core of the
                      engagement
                    </h6>
                  </div>

                  <div class="w-full flex pt-4">
                    <div class="css-1wxaqej">
                      <a
                        class="MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-1mex9d7"
                        tabindex="0"
                        href="mailto:contact@saepio.co.uk"
                        >Contact Us<span
                          class="MuiTouchRipple-root css-w0pj6f"
                        ></span
                      ></a>
                    </div>
                    <div class="css-1wxaqej">
                      <a
                        class="MuiButton-text MuiButton-textPrimary MuiButton-sizeLarge MuiButton-textSizeLarge css-18do3oc"
                        tabindex="0"
                        href="https://saepio.co.uk/how-we-do-it/"
                        >Find Out More<span
                          class="MuiTouchRipple-root css-w0pj6f"
                        ></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-1/3">
                  <img
                    src="./assets/hub-security-report.40e1f52d.png"
                    alt="Online Protection"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <CustomerSlider />
      </div>
    </div>
  </div>
</template>

<script>
import CustomerSlider from "./components/CustomerSlider.vue";
export default {
  name: "SaepioHub",
  components: {
    CustomerSlider,
  },
};
</script>
