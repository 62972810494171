<template>
  <Carousel :autoplay="2000" :items-to-show="itemsToShow" :wrap-around="true">
    <Slide v-for="slide in slides" :key="slide">
      <a class="MuiBox-root css-10klw3m" :href="slide.href"
        ><img
          :src="slide.img"
          :alt="slide.name"
          width="100%"
          style="height: 60px; object-fit: contain"
          class="technologyLogo"
      /></a>
    </Slide>
  </Carousel>
</template>

<script setup>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import { ref, onMounted, onUnmounted } from "vue";
import "vue3-carousel/dist/carousel.css";

const slides = ref([
  {
    href: "https://www.pizzaexpress.com/",
    name: "Pizza Express",
    img: "Pizza-Express.b31d451e.png",
  },
  {
    href: "https://www.bdo.co.uk/",
    name: "BDO",
    img: "BDO.8f9e1511.svg",
  },
  {
    href: "https://www.hobbycraft.co.uk/",
    name: "Hobbycraft",
    img: "HobbyCraft.f9a9eec9.png",
  },
  {
    href: "https://www.bindingsite.com/en",
    name: "The Binding Site",
    img: "Binding-Site.f18107e5.png",
  },
  {
    href: "https://www.suzuki.co.uk/",
    name: "Suzuki",
    img: "suzuki.4a8eff19.png",
  },
  {
    href: "https://www.adrianflux.co.uk/",
    name: "Adrian Flux",
    img: "Adrian-Flux.5358515b.png",
  },
  {
    href: "https://www.thomasmiller.com/",
    name: "Thomas Miller",
    img: "Thomas-Miller.e3630e10.png",
  },
  {
    href: "https://www.bandk.co.uk/",
    name: "Bowmer Kirkland",
    img: "Countryside.0135a3ed.png",
  },
  {
    href: "https://www.countrysidepartnerships.com/",
    name: "Countryside",
    img: "gain.7f285379.png",
  },
  {
    href: "https://www.gainhealth.org/",
    name: "Gain",
    img: "Goals.550ffab4.png",
  },
  {
    href: "https://www.motuscommercials.co.uk/",
    name: "Motus Commercials",
    img: "Motus-Commercials.4c134324.png",
  },
  {
    href: "https://practiceplusgroup.com/",
    name: "Practice Plus Group",
    img: "Practice-Plus-Group.ce60433e.svg",
  },
  {
    href: "https://www.barnett-waddingham.co.uk/",
    name: "Barnett Waddingham",
    img: "Barnett-Waddingham.5daa6fe8.png",
  },
  {
    href: "https://www.wrighthassall.co.uk/",
    name: "Wright Hassall",
    img: "Wright-Hassall.2150acb5.png",
  },
]);

const itemsToShow = ref(8.5);

function updateItemsToShow() {
  const width = window.innerWidth;
  if (width >= 1200) {
    // Large screens
    itemsToShow.value = 8.5;
  } else if (width >= 768 && width < 1200) {
    // Medium screens
    itemsToShow.value = 4.5;
  } else {
    // Small screens
    itemsToShow.value = 2.5;
  }
}

onMounted(() => {
  updateItemsToShow();
  window.addEventListener("resize", updateItemsToShow);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateItemsToShow);
});
</script>
